<template>
  <div class="non-analysis-goods-detail">
    <h2 class="title">{{$route.query.id ? '编辑商品':'添加商品'}}</h2>
    <p style="color:red;margin-bottom:15px;">注：页面里所有的金额都是以日元为单位</p>
    <div class="content">
      <div class="left">
        <div class="item">
          <span><span class="red">*</span>商品名：</span>
          <el-input v-model="params.title" minlength="1" maxlength="150" placeholder="请输入商品名"></el-input>
        </div>
        <div class="item">
          <span><span class="red">*</span>原站商品id：</span>
          <el-input v-model="params.source_id" :disabled="$route.query.id>0" placeholder="请输入原站商品id"></el-input>
        </div>
        <div class="item">
          <span><span class="red">*</span>站点：</span>
          <span>{{site}}</span>
        </div>
        <div class="item">
          <span><span class="red">*</span>商品单价：</span>
          <el-input v-model="params.price" type="number" placeholder="请输入商品日元单价"></el-input>
        </div>
        <div class="item">
          <span>商品折扣价：</span>
          <el-input v-model="params.discount_price" type="number" placeholder="折扣价输入后将以折扣价结算"></el-input>
        </div>
        <div class="item">
          <span><span class="red">*</span>库存：</span>
          <el-input v-model="params.stock" type="number" placeholder="请输入库存"></el-input>
        </div>
        <div class="item">
          <span>购买限制：</span>
          <el-input v-model="params.buy_limit" type="number" placeholder="请输入1-99的数字"></el-input>
        </div>
        <div class="item">
          <span>日本国内运费：</span>
          <el-select v-model="params.ship_fee_in_Japan">
            <el-option label="免费包邮" value="免费包邮"></el-option>
            <el-option label="下单后结算" value="下单后结算"></el-option>
          </el-select>
        </div>
        <div class="item">
          <span>日本国内发货时间：</span>
        </div>
        <div>
          <div class="item">
            <el-radio v-model="radio" :label="1">购买后天数</el-radio>
            <el-input v-model="params.ship_days_in_Japan" type="number" style="margin-left:15px;" placeholder="不填则显示'发货时间不明'"></el-input>
          </div>
          <div class="item">
            <el-radio v-model="radio" :label="2">固定日期</el-radio>
            <el-date-picker v-model="date" type="date" placeholder="请选择日期"  style="margin-left:28px;"></el-date-picker>
          </div>
        </div>
        <div class="item">
          <span>发货地址：</span>
          <el-input v-model="params.delivery_address" maxlength="10" placeholder="不填则显示'发货地不明'"></el-input>
        </div>
        <div class="item">
          <span>物流方式：</span>
          <el-input v-model="params.express_desc" maxlength="20" placeholder="不填则显示'物流方式不明'"></el-input>
        </div>
        <div class="item">
          <span><span class="red">*</span>商品描述：</span>
          <textarea class="textarea" v-model="params.description" maxlength="1000" placeholder="请输入商品描述"></textarea>
        </div>
      </div>
      <div class="right">
        <div class="item" v-for="(item,i) in url" :key="'link'+item.id">
          <span><span class="red">*</span>商品链接{{i+1}}：</span>
          <el-input v-if="i==0" :disabled="$route.query.id>0" v-model="item.link" placeholder="请输入商品链接"></el-input>
          <el-input v-if="i!=0" v-model="item.link" placeholder="请输入商品链接"></el-input>
          <el-button v-if="i!=0" type="danger" @click="url.splice(i,1)">删除</el-button>
        </div>
        <div class="item">
          <span></span>
          <el-button type="primary" @click="url.push({link:''})">添加</el-button>
        </div>
        <div class="item" v-for="(item,i) in imgurl" :key="'img'+i">
          <el-image 
            class="img-icon"
            :src="item.imgurl" 
            :preview-src-list="[item.imgurl]">
          </el-image>
          <span><span class="red">*</span>图片链接{{i+1}}：</span>
          <el-input v-model="item.imgurl" placeholder="请输入图片链接"></el-input>
          <el-button v-if="i!=0" type="danger" @click="imgurl.splice(i,1)">删除</el-button>
        </div>
        <div class="item">
          <span></span>
          <el-button type="primary" @click="imgurl.push({imgurl:''})">添加</el-button>
        </div>
        <div class="item" v-for="(item,i) in tag" :key="'tag'+i">
          <span><span class="red">*</span>商品标签{{i+1}}：</span>
          <el-input v-model="item.tag" maxlength="10" placeholder="请输入商品标签"></el-input>
          <el-button v-if="i!=0" type="danger" @click="tag.splice(i,1)">删除</el-button>
        </div>
        <div class="item">
          <span></span>
          <el-button type="primary" @click="tag.push({tag:''})">添加</el-button>
        </div>
      </div>
    </div>
    <div class="item">
      <span>启用时间：</span>
      <el-date-picker v-model="dateTime" type="daterange" range-separator="至"
              start-placeholder="开始日期"
              end-placeholder="结束日期"
              placeholder="不填写默认永久有效"></el-date-picker>
    <p style="color:red;">不填写默认永久有效</p>
    </div>
    <div class="item">
      <span>商品介质：</span>
      <div class="flex-wrap">
        <el-radio v-for="item in list2" :key="item.id" v-model="params.type" :label="item.id" style="margin-right:10px;padding-top: 10px;">{{item.name}}</el-radio>
      </div>
    </div>
    <div class="item">
      <span>海关分类：</span>
      <div class="flex-wrap">
        <el-radio v-for="item in list" :key="item.id" v-model="params.customs_type" :label="item.id" style="margin-right:10px;padding-top: 10px;">{{item.name}}</el-radio>
      </div>
    </div>
    <footer class="footer">
      <el-button type="primary" @click="$router.push(`/market/goodsManager/non_analysis`)">取消</el-button>
      <el-button type="primary" @click="judge">保存</el-button>
    </footer>
  </div>
</template>
<script>
export default {
  data() {
    return {
      params: {
        id: this.$route.query.id || '',
        title: '',
        source_id: '',
        price: '',
        discount_price: '',
        stock: '',
        buy_limit: '',
        ship_fee_in_Japan: '免费包邮',
        ship_days_in_Japan: '',
        ship_at_in_Japan: '',
        delivery_address: '',
        express_desc: '',
        links: [],
        images: [],
        tags: [],
        type: '',
        customs_type: '',
        use_start_at: undefined,
        use_end_at: undefined,
        site_id: 125
      },
      dateTime: [],
      date: '',
      radio: 1,
      url: [{
        link: ''
      }],
      imgurl: [{
        imgurl: ''
      }],
      tag: [{
        tag: ''
      }],
      site: '',
      list: [],
      list2: [],
    }
  },
  watch: {
    dateTime(val) {
      if(!val || val.length == 0) {
        this.params.use_start_at = undefined
        this.params.use_end_at = undefined
      } else {
        this.params.use_start_at = this.$dayjs(val[0]).startOf('day').unix()
        this.params.use_end_at = this.$dayjs(val[1]).endOf('day').unix()
      }
    },
  },
  mounted() {
    if(this.$route.query.id) {
      this.getDetail()
    } else {
      this.getSite()
    }
    this.getList()
    this.getList2()
  },
  methods: {
    getSite() {
      this.$axios.get(`/common/business/site/getSimplifyList`)
      .then(res=>{
        res.data.forEach(item=>{
          if(item.site_id == 125) {
            this.site = item.proxy_name
          }
        })
      })
    },
    getDetail() {
      this.$axios.get(`/marketCenter/platformRequest/goods/detail/${this.$route.query.id}`)
      .then(res=>{
        Object.assign(this.params,res.data)
        this.site = res.data.site_proxy_name
        // this.getId(res.data.get_links[0])
        this.url = []
        this.imgurl = []
        this.tag = []
        if(res.data.use_start_at && res.data.use_end_at) {
          this.dateTime = [this.$dayjs(res.data.use_start_at*1000),this.$dayjs(res.data.use_end_at*1000)]
        }
        res.data.get_links.forEach(item=>{
          this.url.push(item)
        })
        res.data.images.forEach(item=>{
          this.imgurl.push({imgurl:item})
        })
        res.data.tags.forEach(item=>{
          this.tag.push({tag:item})
        })
        if(res.data.ship_days_in_Japan == -1) {
          this.radio = 1
          this.params.ship_days_in_Japan = null
        }
        if(res.data.ship_days_in_Japan == 0) {
          this.params.ship_days_in_Japan = null
        }
        if(res.data.ship_days_in_Japan == 0 && res.data.ship_at_in_Japan) {
          this.radio = 2
          this.date = this.$dayjs(res.data.ship_at_in_Japan*1000)
        }
      })
    },
    getList() {
      this.$axios.get(`/common/business/customsType/getList`)
      .then(res=>{
        for(let key in res.data) {
          this.list.push({id: Number(key),name:res.data[key]})
        }
      })
      
    },
    getList2() {
      this.$axios.get(`/common/business/productType/getList`)
      .then(res=>{
        for(let key in res.data) {
          this.list2.push({id: Number(key),name:res.data[key]})
        }
      })
      
    },
    judge() {
      if(!this.params.title) {
        this.$message.warning('请输入商品名')
        return
      }
      if(!this.params.source_id) {
        this.$message.warning('请输入原站商品id')
        return
      }
      if(!this.params.price) {
        this.$message.warning('请输入商品单价')
        return
      }
      if(!this.params.stock) {
        this.$message.warning('请输入库存')
        return
      }
      if(!this.params.title) {
        this.$message.warning('请输入商品名')
        return
      }
      if(!this.params.description){
        this.$message.warning('请输入商品描述')
        return
      }
      this.params.links = []
      let flag = false
      let flag2 = false
      let flag3 = false
      this.url.forEach(e=>{
        if(!e.link) {
          flag = true
        }
        this.params.links.push(e.link)
      })
      if(flag) {
        this.$message.warning('存在商品链接未输入')
        return
      }
      this.params.images = []
      this.imgurl.forEach(e=>{
        if(!e.imgurl) {
          flag2 = true
        }
        this.params.images.push(e.imgurl)
      })
      if(flag2) {
        this.$message.warning('存在图片链接未输入')
        return
      }
      this.params.tags = []
      this.tag.forEach(e=>{
        if(!e.tag) {
          flag3 = true
        }
        this.params.tags.push(e.tag)
      })
      if(flag3) {
        this.$message.warning('存在商品标签未输入')
        return
      }
      if(this.params.links.length == 0) {
        this.$message.warning('请输入商品链接')
        return
      }
      if(this.params.images.length == 0) {
        this.$message.warning('请输入图片链接')
        return
      }
      if(this.params.tags.length == 0) {
        this.$message.warning('请输入商品标签')
        return
      }
      if(this.params.price && this.params.price <= 0) {
        this.$message.warning('商品单价不能小于等于0')
        return
      }
      if(this.params.price && this.params.price > 1000000) {
        this.$message.warning('商品单价不能大于1000000')
        return
      }
      if(this.params.discount_price && this.params.discount_price <= 0) {
        this.$message.warning('商品折扣价不能小于等于0')
        return
      }
      if(this.params.discount_price && this.params.discount_price > 1000000) {
        this.$message.warning('商品折扣价不能大于1000000')
        return
      }
      if(this.params.stock <= 0) {
        this.$message.warning('库存不能小于等于0')
        return
      }
      if(this.params.stock > 1000000) {
        this.$message.warning('库存不能大于1000000')
        return
      }
      if(this.params.buy_limit && this.params.buy_limit <= 0) {
        this.$message.warning('库存不能小于等于0')
        return
      }
      if(this.params.buy_limit && this.params.buy_limit > 99) {
        this.$message.warning('购买限制不能大于99')
        return
      }
      this.params.ship_days_in_Japan = Number(this.params.ship_days_in_Japan)
      if(this.radio == 1 && this.params.ship_days_in_Japan && this.params.ship_days_in_Japan <= 0) {
        this.$message.warning('天数不能小于等于0')
        return
      }
      if(this.radio == 2 && !this.date) {
        this.$message.warning('请选择固定日期')
        return
      }
      if(this.radio == 1 && !this.params.ship_days_in_Japan) {
        this.params.ship_days_in_Japan = -1
        this.params.ship_at_in_Japan = 0
      }
      if(this.radio == 1) {
        this.params.ship_at_in_Japan = 0
      }
      if(this.radio == 2) {
        this.params.ship_days_in_Japan = 0
        this.params.ship_at_in_Japan = this.$dayjs(this.date).unix()
      }
      this.save()
    },
    save() {
      this.$axios.post(`/marketCenter/platformRequest/goods/save`,this.params)
      .then(res=>{
        this.$message.success(res.msg)
        this.$router.push(`/market/goodsManager/non_analysis`)
      })
      
    }
  },
}
</script>
<style lang="scss" scoped>
.non-analysis-goods-detail{
  padding: 30px 50px !important;
  .title{
    margin-bottom: 15px;
  }
  .content{
    display: flex;
    &>div{
      width: 50%;
    }
  }
  .item{
    display: flex;
    align-items: center;
    white-space: nowrap;
    margin-bottom: 15px;
    padding-right: 30px;
    .red{
      color: red;
    }
    &>span:nth-of-type(1){
      min-width: 90px;
      display: block;
    }
    .img-icon{
      width: 50px;
      height: 50px;
      margin-right: 15px;
      flex-shrink: 0;
    }
    .flex-wrap {
      flex-wrap: wrap;
      display: flex;
    }
  }
  .textarea{
    height: 200px;
    width: 300px;
    padding: 5px 10px;
  }
  .footer{
    display: flex;
    justify-content: space-around;
    margin-top: 30px;
  }
}
</style>